// GET ALL DOCUMENTS
export const GET_ALL_DATABASES_REQUESTED = "GET_ALL_DATABASES_REQUESTED";
export const GET_ALL_DATABASES_SUCCESS = "GET_ALL_DATABASES_SUCCESS";
export const GET_ALL_DATABASES_FAILED = "GET_ALL_DATABASES_FAILED";

export const AI_QUERY_REQUESTED = "AI_QUERY_REQUESTED";
export const AI_QUERY_SUCCESS = "AI_QUERY_SUCCESS";
export const AI_QUERY_FAILED = "AI_QUERY_FAILED";

// CREATE A DOCUMENT
export const ADD_DATABASE_REQUESTED = "ADD_DATABASE_REQUESTED";
export const ADD_DATABASE_SUCCESS = "ADD_DATABASE_SUCCESS";
export const ADD_DATABASE_FAILED = "ADD_DATABASE_FAILED";

// SET A DOCUMENT DETAILS
export const DATABASE_SET_DETAILS = "DATABASE_SET_DETAILS";

// UPDATE A DOCUMENT
export const UPDATE_DATABASE_REQUESTED = "UPDATE_DATABASE_REQUESTED";
export const UPDATE_DATABASE_SUCCESS = "UPDATE_DATABASE_SUCCESS";
export const UPDATE_DATABASE_FAILED = "UPDATE_DATABASE_FAILED";

// DELETE A DATABASE DOCUMENT
export const DELETE_DATABASE_DOCUMENT_REQUESTED =
  "DELETE_DATABASE_DOCUMENT_REQUESTED";
export const DELETE_DATABASE_DOCUMENT_SUCCESS =
  "DELETE_DATABASE_DOCUMENT_SUCCESS";
export const DELETE_DATABASE_DOCUMENT_FAILED =
  "DELETE_DATABASE_DOCUMENT_FAILED";

// DELETE A DOCUMENT
export const DELETE_DATABASE_REQUESTED = "DELETE_DATABASE_REQUESTED";
export const DELETE_DATABASE_SUCCESS = "DELETE_DATABASE_SUCCESS";
export const DELETE_DATABASE_FAILED = "DELETE_DATABASE_FAILED";

// DOCUMENT MODAL
export const SET_DATABASE_MODAL_REQUESTED = "SET_DATABASE_MODAL_REQUESTED";

// RESET THE DUPLICATE ERROR MESSAGE
export const RESET_DATABASES_ERRORS_REQUESTED =
  "RESET_DATABASES_ERRORS_REQUESTED";

// CHANGE SEARCH
export const CHANGE_DATABASES_SEARCH_REQUESTED =
  "CHANGE_DATABASES_SEARCH_REQUESTED";
