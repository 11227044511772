import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

export const UnityModal = ({ open, onClose, partName, partData }) => {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    requestFullscreen,
    sendMessage,
  } = useUnityContext({
    loaderUrl: partName
      ? "/build/AI3DViewer.loader.js"
      : "/build/3DViewer.loader.js",
    dataUrl: partName ? "/build/AI3DViewer.data" : "/build/3DViewer.data",
    frameworkUrl: partName
      ? "/build/AI3DViewer.framework.js"
      : "/build/3DViewer.framework.js",
    codeUrl: partName ? "/build/AI3DViewer.wasm" : "/build/3DViewer.wasm",
  });

  useEffect(() => {
    if (isLoaded && (partName || partData)) {
      if (partName) {
        sendMessage("ModelViewport", "HighlightPart", partName);
      } else if (partData) {
        sendMessage(
          "ModelViewport",
          "Load3DModel",
          JSON.stringify(partData).replace(/”/g, ""),
        );
      }
    }
  }, [isLoaded, partName, sendMessage, partData]);

  // We'll use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio,
  );

  useEffect(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`,
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio],
  );

  const handleClickEnterFullscreen = () => {
    requestFullscreen(true);
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          open={open}
          onClose={() => onClose()}
          className="fixed inset-0 z-50 flex items-center xl:items-center justify-center bg-black2 dark:bg-darkMainBg bg-opacity-40 dark:bg-opacity-60"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="relative w-[90%] h-[auto] bg-gray4 dark:bg-darkBg dark:text-gray2 border border-gray4 dark:border-opacity-20 shadow-lg">
              <div className="flex items-center justify-center unity-container">
                {!isLoaded && (
                  <p className="loading-overlay">
                    Loading 3D Model... {Math.round(loadingProgression * 100)}%
                  </p>
                )}
                <Unity
                  unityProvider={unityProvider}
                  className="unity"
                  // style={{
                  //   visibility: isLoaded && partName === "123" ? "visible" : "hidden",
                  //   width: "100%",
                  //   height: 800,
                  // }}
                  devicePixelRatio={devicePixelRatio}
                />
              </div>
              <div className="flex items-center justify-end bg-gray4 dark:bg-darkBg py-3 px-12">
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="bg-transparent text-black2 dark:text-gray2 md:text-sm 2xl:text-base font-medium border border-black2 dark:border-gray2 rounded-full px-8 py-2 shadow-sm transition-all duration-300 hover:bg-black2 dark:hover:bg-gray2 hover:text-white dark:hover:text-black3 hover:transition-all hover:duration-300 focus:outline-0 focus-visible:outline-0"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleClickEnterFullscreen}
                  className="bg-transparent text-black2 dark:text-gray2 md:text-sm 2xl:text-base font-medium border border-black2 dark:border-gray2 rounded-full px-8 py-2 ml-4 shadow-sm transition-all duration-300 hover:bg-black2 dark:hover:bg-gray2 hover:text-white dark:hover:text-black3 hover:transition-all hover:duration-300 focus:outline-0 focus-visible:outline-0"
                >
                  Enter Fullscreen
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
